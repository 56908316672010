import Layout from "../components/layout"
import Seo from "../components/seo"
import BackgroundImage from "gatsby-background-image"
import { convertToBgImage } from "gbimage-bridge"
import Fade from "react-reveal/Fade"
// Developed by Explorelogy
import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image"
import { FolderRemoveIcon } from "@heroicons/react/solid"

const News = ({ data }) => {
  const [active, setActive] = useState(0)

  const images = {
    salmon_texture: convertToBgImage(getImage(data.salmon_texture)),
    Post1: getImage(data.Post1),
    Post2: getImage(data.Post2),
    Post3: getImage(data.Post3),
    Post4: getImage(data.Post4),
    blog_metavers: getImage(data.blog_metavers),
    mfd: getImage(data.mfd),
  }

  let blogs = [
    {
      category: ["innovation", "", "", "", "sustainability"],
      title: "MFD for electronically enhanced Soft goods:",
      line1: "",
      line2:
        "18th August 2022 | Ross D Alcazar – Chief Operating Officer, Softmatter",
      line3:
        "The topic of productization and some of our challenges today is at the forefront of our evolution. By finding ways to continually innovate",
      link: "/news/mfd-for-electronically-enhanced-soft-goods",
      image: images.mfd,
    },
    {
      category: ["innovation", "consumer technology"],
      title: "Unlocking the Potential Of The Metaverse:",
      line1: "",
      line2:
        "16th August 2022 | Nivantha Bandara – Program Manager, Softmatter ",
      line3:
        "Why should we be excited about the rising popularity of the integration between virtual and physical spaces?",
      link: "/news/unlocking-the-potential-of-the-metaverse",
      image: images.blog_metavers,
    },
    {
      category: ["innovation", "consumer technology"],
      title: "Reinventing devices for today and tomorrow:",
      line1: "Through the lens of wearable technology and smart textiles",
      line2:
        "Sep 16, 2021 | Product Engineering, Audio Devices, Smart Textiles",
      line3:
        "Smart soft technologies ushering a new age of advanced personal devices – with a focus in the audio device market.",
      link: "/news/reinventing-devices-for-today-and-tomorrow",
      image: images.Post1,
    },
    {
      category: ["innovation", "consumer technology"],
      title: "Game Changer for AR and VR: Smart Textiles",
      line1: "",
      line2:
        "Sep 16, 2021 | Product Engineering, AR/VR, Smart TextilesThrough the lens of wearable technology and smart textiles",
      line3:
        "The first stereoscope. Arcade machines. And a Sayre Glove. Snapchat lenses, Microsoft HoloLens and Google maps AR navigation systems that superimposing...",
      link: "/news/game-changer-for-AR-and-VR",
      image: images.Post2,
    },
    {
      category: [
        "innovation",
        "consumer technology",
        "outdoor and performance",
      ],
      title:
        "Evolved from a futuristic accessory to a ubiquitous health & wellness wearable: ",
      line1: "Engineering human factors in smartwatches and wristables",
      line2:
        "16th October 2021 | Health and wellness Wearables, Engineered Knit, Product Engineering",
      line3:
        "What factors will gear more consumers to be more comfortable in having a virtual health assistant strapped to their wrist?",
      link: "/news/evolved-from-a-futuristic-accessory-to-a-ubiquitous-health-&-wellness-wearable",
      image: images.Post3,
    },
    {
      category: [
        "innovation",
        "consumer technology",
        "outdoor and performance",
        "healthcare",
      ],
      title: "Tomorrow’s challenges demand new approaches:",
      line1: "Smart soft technology integration in medical devices",
      line2: "",
      line3:
        "Making accessibility and affordability core to medtech product innovation in RPM, pain, sleep and respiratory care. ",
      link: "/news/tomorrows-challenges-demand-new-approaches",
      image: images.Post4,
    },
  ]

  const categories = [
    {
      category: "view all",
    },
    {
      category: "INNOVATION",
    },
    {
      category: "SUSTAINABILITY",
    },
    {
      category: "HEALTHCARE",
    },
    {
      category: "CONSUMER TECHNOLGY",
    },
    {
      category: "OUTDOOR and PERFORMANCE",
    },
  ]

  if (active == 1) {
    const innovation = blogs.filter(blog => {
      return blog.category[0] == "innovation"
    })
    blogs = innovation
  } else if (active == 2) {
    const sustainability = blogs.filter(blog => {
      return blog.category[4] == "sustainability"
    })
    blogs = sustainability
  } else if (active == 3) {
    const healthcare = blogs.filter(blog => {
      return blog.category[3] == "healthcare"
    })
    blogs = healthcare
  } else if (active == 4) {
    const consumerTechnology = blogs.filter(blog => {
      return blog.category[1] == "consumer technology"
    })
    blogs = consumerTechnology
  } else if (active == 5) {
    const outdoorPerformance = blogs.filter(blog => {
      return blog.category[2] == "outdoor and performance"
    })
    blogs = outdoorPerformance
  }

  //   console.log(innovation)

  return (
    <Layout>
      <Seo title="News" />
      <section>
        <BackgroundImage
          {...images.salmon_texture}
          className="h-header bg-salmon"
        >
          <div className="container h-full mx-auto">
            <div className="flex h-full items-center">
              <Fade bottom cascade>
                <div className="flex flex-col ">
                  <div className="sm:text-6xl text-4xl  sm:text-left text-center text-white">
                    Latest from Softmatter
                  </div>
                  {/* <div className="sm:text-2xl text-xl sm:max-w-lg text-white font-light mt-4  sm:text-left text-center">
                    Computerizing the weaving of yarn for complex construction
                  </div> */}
                </div>
              </Fade>
            </div>
          </div>
        </BackgroundImage>
      </section>
      <section>
        <div className="container mx-auto overflow-hidden">
          <div className="py-24">
            <div className="w-full mb-5 lg:hidden block">
              <Fade right cascade>
                <div className="">
                  <div className="text-salmon text-sm sm:text-left text-center font-firs-medium mb-4">
                    Topics
                  </div>
                </div>
                <div className="text-sm flex flex-wrap sm:justify-start justify-center gap-5">
                  {categories.map((item, index) => {
                    return (
                      <div className="" key={index}>
                        <span
                          className={
                            active == index
                              ? "uppercase border-b-2 border-salmon cursor-pointer hover:text-salmon duration-200 font-firs-regular"
                              : "uppercase border-b-2 border-transparent cursor-pointer hover:text-salmon duration-200 font-firs-regular"
                          }
                          onClick={() => setActive(index)}
                        >
                          {item.category}
                        </span>
                      </div>
                    )
                  })}
                </div>
              </Fade>
            </div>
            <div className="flex gap-10 justify-center">
              <div className="lg:w-9/12">
                <Fade bottom>
                  <div className="grid lg:grid-cols-2 gap-8 w-full">
                    {blogs != "" ? (
                      <>
                        {blogs.map((item, index) => {
                          return (
                            <div className="" key={index}>
                              <GatsbyImage
                                image={item.image}
                                alt="Softmatter"
                              />
                              <div className="mb-2 text-sm mt-4 text-salmon font-firs-medium">
                                {item.title}
                              </div>
                              <div className="text-sm mb-2">{item.line1}</div>
                              <div className="text-sm mb-2">{item.line2}</div>
                              <div className="text-sm mb-2">{item.line3}</div>
                              <div className="text-sm mt-2 text-salmon font-firs-medium">
                                <Link to={item.link}>
                                  <span className="hover:underline cursor-pointer">
                                    Read More
                                  </span>
                                </Link>
                              </div>
                            </div>
                          )
                        })}
                      </>
                    ) : (
                      <>
                        <div className="flex justify-center h-60 items-center col-span-2">
                          <div className="flex flex-col items-center">
                            <FolderRemoveIcon
                              width={80}
                              className="text-gray-400"
                            />
                            <div className="text-lg text-gray-400 text-center">
                              No available blogs for{" "}
                              <span className="lowercase">
                                {" "}
                                {categories[active].category}
                              </span>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Fade>
              </div>
              <div className="lg:block hidden">
                <div className="sticky top-0 -webkit-sticky">
                  <Fade right cascade>
                    <div className="">
                      <div className="text-salmon text-sm font-firs-medium mb-4">
                        Topics
                      </div>
                    </div>
                    <div className="text-sm">
                      {categories.map((item, index) => {
                        return (
                          <div className="mb-5" key={index}>
                            <span
                              className={
                                active == index
                                  ? "uppercase border-b-2 border-salmon cursor-pointer hover:text-salmon duration-200 font-firs-regular"
                                  : "uppercase border-b-2 border-transparent cursor-pointer hover:text-salmon duration-200 font-firs-regular"
                              }
                              onClick={() => setActive(index)}
                            >
                              {item.category}
                            </span>
                          </div>
                        )
                      })}
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query NewsImages {
    salmon_texture: file(relativePath: { eq: "textures/salmon_texture.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Post1: file(relativePath: { eq: "news/Post1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Post2: file(relativePath: { eq: "news/Post2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Post3: file(relativePath: { eq: "news/Post3.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    Post4: file(relativePath: { eq: "news/Post4.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    blog_metavers: file(relativePath: { eq: "news/blog_metavers.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
    mfd: file(relativePath: { eq: "news/mfd.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920, quality: 100, placeholder: BLURRED)
      }
    }
  }
`

export default News
